import firebase from 'firebase/app';
import 'firebase/firestore';

// noinspection SpellCheckingInspection
const config = {
    apiKey: "AIzaSyCeCCKzqjf29vTVjp0MeHz0SIDJbSTQg1k",
    authDomain: "jon-bartlett-3ced8.firebaseapp.com",
    databaseURL: "https://jon-bartlett-3ced8.firebaseio.com",
    projectId: "jon-bartlett-3ced8",
    storageBucket: "jon-bartlett-3ced8.appspot.com",
    messagingSenderId: "450686733439"
};

const app = firebase.initializeApp(config);

// Firestore
const firestore = app.firestore();
const musicDbRef = firestore.collection('music');

export {musicDbRef}