// React
import * as React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
// CSS
import './recentTrack.css';
import Text from '../../common/text';

export default class RecentTrack extends React.Component {
    render() {
        return (
            <Link to={this.props.trackUrl}>
                <div className='recentTrack'>
                    <img className='image' src={this.props.imageUrl} alt={this.props.trackTitle}/>
                    <div className='text'>
                        <Text font='p' color='light' noPadding={true}>{this.props.trackTitle}</Text>
                    </div>
                </div>
            </Link>
        )
    }
}

RecentTrack.propTypes = {
    trackTitle: PropTypes.string.isRequired,
    trackUrl: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
};