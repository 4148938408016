// React
import * as React from 'react';
import PropTypes from 'prop-types'
// Layout
import Section from '../layout/section';
import Row from '../layout/row';
import Column from '../layout/column';
import Padding from '../layout/padding';
// Common
import Text from '../common/text';
// Components
import RecentTracks from '../components/recentTracks/recentTracks';
import Footer from '../components/footer';
import NavBar from '../components/navbar';

export default class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageTitle: 'Home'
        }
    }

    componentDidMount() {
        document.title = this.props.siteTitle + ' - ' + this.state.pageTitle;
    }

    render() {
        let heroImage = require('../images/heroImage.jpg');
        return (
            <React.Fragment>
                <NavBar />

                {/* Hero */}
                <Section sectionStyle={{
                    backgroundImage: `url(${heroImage})`,
                    backgroundPosition: 'center',
                    backgroundAttachment: 'fixed',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    paddingTop: '30vh',
                    paddingBottom: '30vh',
                }}>
                    {/* <Section> */}
                    <Row>
                        <Column>
                            <Padding>
                                <Text font='h1' color='light'>Professional music production</Text>
                            </Padding>
                        </Column>
                    </Row>
                </Section>

                {/* Jon intro */}
                <Section sectionStyle={{ backgroundColor: '#E2EEEE' }}>
                    <Row>
                        <Column>
                            <Padding>
                                <Text font='h3'>About Jon Bartlett</Text>
                                <Text font='p'>Jon Bartlett studied performance and composition at the Royal College of Music in London.</Text>
                                <Text font='p'>Over the last 20 years he has worked extensively as a commercial composer, arranger and session musician. His early career started with MCA publishing as an in house writer, and saw him complete numerous collaborations with MCA Universal and The Screened Music Academy.</Text>
                                <Text font='p'>As an arranger he works in the fields of Musical Theatre and Commercial Pop.</Text>
                                <Text font='p'>Jon is a director of Melodies Music Education in NorthWest London, a music education hub founded specifically, to encourage and develop young new talent in the fields of film music composition and Sound Design.</Text>
                            </Padding>
                        </Column>
                    </Row>
                </Section>

                {/* Categories */}
                <Section sectionStyle={{ backgroundColor: '#6AB7DF' }}>
                    <Row>
                        <Column>
                            <Padding>
                                <Text font='h3' color='light'>Composition</Text>
                                <Text font='p' color='light'>Sed pellentesque magna eros, in facilisis nibh blandit sed. Etiam eu laoreet lectus. Quisque accumsan leo enim, id fringilla elit accumsan nec.</Text>
                                <Text font='p' color='light'>Maecenas ac consectetur elit, a pulvinar nisl. Cras malesuada, leo sed dictum bibendum, dolor magna mattis mi, nec lobortis metus nunc id felis. Nunc vitae risus felis.</Text>
                                <Text font='p' color='light'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae vehicula sem, nec lobortis purus. Aenean luctus ex ac urna imperdiet sagittis.</Text>
                                <Text font='p' color='light'>In porttitor imperdiet diam non pretium. Sed tempus mi eu magna egestas dignissim. Aenean urna nulla, luctus sit amet rhoncus laoreet, placerat non tellus. Phasellus id eleifend est. Integer blandit massa a mi consequat, ac tincidunt lorem hendrerit.</Text>
                            </Padding>
                        </Column>

                        <Column>
                            <Padding>
                                <Text font='h3' color='light'>Arrangement</Text>
                                <Text font='p' color='light'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae vehicula sem, nec lobortis purus. Aenean luctus ex ac urna imperdiet sagittis.</Text>
                                <Text font='p' color='light'>Sed pellentesque magna eros, in facilisis nibh blandit sed. Etiam eu laoreet lectus. Quisque accumsan leo enim, id fringilla elit accumsan nec.</Text>
                                <Text font='p' color='light'>Maecenas ac consectetur elit, a pulvinar nisl. Cras malesuada, leo sed dictum bibendum, dolor magna mattis mi, nec lobortis metus nunc id felis. Nunc vitae risus felis.</Text>
                                <Text font='p' color='light'>In porttitor imperdiet diam non pretium. Sed tempus mi eu magna egestas dignissim. Aenean urna nulla, luctus sit amet rhoncus laoreet, placerat non tellus. Phasellus id eleifend est. Integer blandit massa a mi consequat, ac tincidunt lorem hendrerit.</Text>
                            </Padding>
                        </Column>
                    </Row>
                </Section>

                {/* Recently released */}
                <Section sectionStyle={{ backgroundColor: '#3E3C63' }}>
                    {/* Title */}
                    <Row>
                        <Column>
                            <Padding>
                                <Text font='h3' color='light'>Latest releases</Text>
                                <RecentTracks />
                            </Padding>
                        </Column>
                    </Row>
                </Section>

                <Footer />
            </React.Fragment>
        );
    }
}

HomePage.propTypes = {
    siteTitle: PropTypes.string.isRequired,
};