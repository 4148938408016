// React
import * as React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// Pages
import HomePage from './pages/homePage';
import MusicPage from './pages/musicPage';
import UnknownPage from './pages/unknownPage';
// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
library.add(faArrowCircleRight);

let siteTitle = 'Jon Bartlett';

export default class App extends React.Component {
    static page_HomePage() {
        return (
            <HomePage siteTitle={siteTitle} />
        );
    }

    static page_MusicPage() {
        return (
            <MusicPage siteTitle={siteTitle} />
        );
    }

    static page_UnknownPage() {
        return (
            <UnknownPage siteTitle={siteTitle} />
        );
    }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route exact path='/' component={App.page_HomePage} />
                    <Route exact path='/music' component={App.page_MusicPage} />
                    <Route exact path='*' component={App.page_UnknownPage} />
                </Switch>
            </BrowserRouter>
        );
    }
}
