// React
import * as React from 'react';
import { Link } from 'react-router-dom';
// Layout
import Section from '../layout/section';
import Row from '../layout/row';
import Column from '../layout/column';
import Padding from '../layout/padding';
// CSS
import Text from '../common/text';

export default class NavBar extends React.Component {
    render() {
        return (
            <Section sectionStyle={{ backgroundColor: '#F77455' }}>
                <Row>
                    <Column>
                        <Padding>
                            <Link to='/'><Text font='h2' color='light'>Jon Bartlett</Text></Link>
                        </Padding>
                    </Column>
                </Row>
            </Section>
        );
    }
}