// React
import * as React from 'react';
// CSS
import './recentTracks.css';
// Firebase
import {musicDbRef} from "../../firebase";
// Other
import RecentTrack from './recentTrack';

export default class RecentTracks extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            musicItems: []
        }
    }

    componentDidMount() {
        this.getLatestMusicItems()
    }

    getLatestMusicItems() {
        musicDbRef.orderBy('datePublished', 'desc').limit(4).onSnapshot(
            (snapshot) => {
                let musicItems = snapshot.docs.map((doc, idx) => {
                    let docData = doc.data();

                    return (
                        <RecentTrack
                            key={idx}
                            trackTitle={docData.title}
                            trackUrl={docData.link}
                            imageUrl={docData.artwork}/>
                    )
                });

                this.setState({
                    musicItems: musicItems
                })
            },
            (err) => {
                console.log('Error getting "music" documents', err);

                this.setState({
                    musicItems: []
                })
            }
        )
    }

    render() {
        return (
            <div className='recentTracks'>
                {this.state.musicItems}
            </div>
        );
    }
}