// React
import * as React from 'react';
import PropTypes from 'prop-types';
// CSS
import './text.css';

export default class Text extends React.Component {
    static get_font(font) {
        switch (font) {
            case 'h1':
                return 'textFontH1';
            case 'h2':
                return 'textFontH2';
            case 'h3':
                return 'textFontH3';
            case 'p':
                return 'textFontP';
            case 'caption':
                return 'textFontCaption';
            default:
                return 'textFontDefault';
        }
    }

    static get_color(color) {
        switch (color) {
            case 'light':
                return 'textColorLight';
            case 'dark':
                return 'textColorDark';
            default:
                return 'textColorDark';
        }
    }

    static get_no_padding(noPadding) {
        switch (noPadding) {
            case true:
                return 'textNoPadding';
            default:
                return '';
        }
    }

    render() {
        let fontStyle = Text.get_font(this.props.font);
        let colorStyle = Text.get_color(this.props.color);
        let noPadding = Text.get_no_padding(this.props.noPadding);

        let styles = `${fontStyle} ${colorStyle} ${noPadding}`;

        return (
            <div className={styles}>
                {this.props.children}
            </div>
        );
    }
}

Text.propTypes = {
    font: PropTypes.string.isRequired,
    color: PropTypes.string,
    noPadding: PropTypes.bool,
};