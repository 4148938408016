// React
import * as React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types'
// Layout
import Section from '../layout/section';
import Row from '../layout/row';
import Column from '../layout/column';
import Padding from '../layout/padding';
// Common
import Text from '../common/text';
// Components
import Footer from '../components/footer';
import NavBar from '../components/navbar';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class UnknownPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageTitle: 'Page not found'
        }
    }

    componentDidMount() {
        document.title = this.props.siteTitle + ' - ' + this.state.pageTitle;
    }

    render() {
        return (
            <React.Fragment>
                <NavBar />

                <Section>
                    <Row>
                        <Column>
                            <Padding>
                                <Text font='h3'>Error 404 - Page not found</Text>
                                <Text font='p'>The page you were looking for could not be found.</Text>
                                <Link to='/'><Text font='p'>Click here to go back home <FontAwesomeIcon icon="arrow-circle-right" /></Text></Link>
                            </Padding>
                        </Column>
                    </Row>
                </Section>

                <Footer />
            </React.Fragment >
        );
    }
}

UnknownPage.propTypes = {
    siteTitle: PropTypes.string.isRequired,
};