// React
import * as React from 'react';
// Layout
import Section from '../layout/section';
import Row from '../layout/row';
import Column from '../layout/column';
import Padding from '../layout/padding';
// CSS
import Text from '../common/text';
// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class Footer extends React.Component {
    render() {
        return (
            <Section sectionStyle={{ backgroundColor: '#F77455' }}>
                <Row>
                    <Column>
                        <Padding>
                            <a href='https://www.google.com/' target='_blank' rel='noopener noreferrer'>
                                <Text font='p' color='light'><FontAwesomeIcon icon="arrow-circle-right" /> My full collection</Text>
                            </a>
                            <a href='mailto:jonbartlett.music@gmail.com'>
                                <Text font='p' color='light'><FontAwesomeIcon icon="arrow-circle-right" /> Send me an email</Text>
                            </a>
                            <Text font='p' color='light'>Copyright &copy; Jon Bartlett {new Date().getFullYear()}</Text>
                        </Padding>
                    </Column>
                </Row>
            </Section>
        );
    }
}