// React
import * as React from 'react';
import PropTypes from 'prop-types'
// Layout
import Section from '../layout/section';
import Row from '../layout/row';
import Column from '../layout/column';
import Padding from '../layout/padding';
// Common
import Text from '../common/text';
// Components
import Footer from '../components/footer';
import NavBar from '../components/navbar';
import Tracks from '../components/tracks/tracks';

export default class MusicPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageTitle: 'Music'
        }
    }

    componentDidMount() {
        document.title = this.props.siteTitle + ' - ' + this.state.pageTitle;
    }

    render() {
        return (
            <React.Fragment>
                <NavBar/>

                {/* Jon intro */}
                <Section sectionStyle={{backgroundColor: '#3E3C63'}}>
                    <Row>
                        <Column>
                            <Padding>
                                <Text font='h2' color='light'>Music</Text>
                                <Tracks/>
                            </Padding>
                        </Column>
                    </Row>
                </Section>

                <Footer/>
            </React.Fragment>
        );
    }
}

MusicPage.propTypes = {
    siteTitle: PropTypes.string.isRequired,
};