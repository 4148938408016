// React
import * as React from 'react';
// CSS
import './section.css';

export default class Section extends React.Component {
    render() {
        return (
            <div className='section' style={this.props.sectionStyle}>
                {this.props.children}
            </div >
        );
    }
}