// React
import * as React from 'react';
// CSS
import './padding.css';

export default class Padding extends React.Component {
    render() {
        return (
            <div className='padding'>
                {this.props.children}
            </div>
        );
    }
}