// React
import * as React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
// CSS
import './track.css';
import Text from '../../common/text';

export default class Track extends React.Component {
    render() {
        return (
            <Link to={this.props.trackUrl}>
                <div className='track'>
                    <img className='image' src={this.props.imageUrl} alt={this.props.trackTitle}/>
                    <div className='title'>
                        <Text font='p' color='light' noPadding={true}>{this.props.trackTitle}</Text>
                    </div>
                    <div className='time'>
                        <Text font='p' color='light' noPadding={true}>{this.props.trackTime}</Text>
                    </div>
                    <div className='genre'>
                        <Text font='p' color='light' noPadding={true}>{this.props.trackGenre}</Text>
                    </div>
                    <div className='link'>
                        <Text font='p' color='light' noPadding={true}>Link</Text>
                    </div>
                </div>
            </Link>
        )
    }
}

Track.propTypes = {
    trackUrl: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    trackTitle: PropTypes.string.isRequired,
    trackGenre: PropTypes.string.isRequired,
    trackTime: PropTypes.string.isRequired,
};