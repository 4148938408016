// React
import * as React from 'react';
import PropTypes from 'prop-types'
// CSS
import './row.css';

export default class Row extends React.Component {
    render() {
        let styles = 'row';

        if (!this.props.wide) {
            styles += ' shortRow';
        }

        return (
            <div className={styles} style={this.props.rowStyle}>
                {this.props.children}
            </div >
        );
    }
}

Row.propTypes = {
    wide: PropTypes.bool,
};
